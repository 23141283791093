import React, { useContext, useState } from 'react'
//custom imports
import styles from '../../../assets/stylesheets/pages/audit/audit.module.scss'
import Heading from '../../../uicomponents/heading/heading'
import Panel from '../../../uicomponents/panel/panel'
import ThreeDotLoader from '../../../components/utilities/ThreeDotLoader'
import { AnswerIcon } from '../../../assets/images'
import capitalise from '../../../helper/Capitalise'
import { RebuttalContext } from '../rebuttal/rebuttalContext'
import FutworkButton from '../../../uicomponents/button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const QmfSidebar = (props) => {
    const {callDetails} = useContext(RebuttalContext)
    const {callHistoryLoading, callHistory, rebuttalHandler, teletaskLoading, teletask,
        teletaskHistoryData, teletaskHistoryDataLoading
    } = props
    const {feedbacks: rebuttalDetails, qmfScoreCard} = callDetails || {}
    const {callLanguage, previousOutcome} = qmfScoreCard || {}
    const {responses: dataCollected, exotel:{Details: {DisconnectedBy: disconnectedBy} = {}} = {}} = teletaskHistoryData || {}
    const {recordingUrl, outcome, data: customerInfo} = teletask || {}
    const [rebuttalResult, setRebuttalResult] = useState({
        loading: false
    })

    const uiArrangementOrder = [
        {
            position: 1,
            component: renderDataCollected
        }
    ]
    // return <></>
    const renderCallHistory = () => {
        return callHistoryLoading ? <ThreeDotLoader /> :
            callHistory && callHistory.length > 0 ? callHistory.map(({ outcome, attempts, recordingUrl }) => {
                return <div className={styles.call_history}>
                    <span className={styles.outcome}>Attempt {attempts || 'NA'}: {outcome ? outcome.title : 'NA'}</span>
                    <audio controls src={recordingUrl || ''} />
                </div>
            }) : <div className={styles.call_history}>⚠️ No call history present</div>
    }

    const renderDataCollected = () => {
        return teletaskHistoryDataLoading ? <ThreeDotLoader/> : dataCollected && dataCollected.length > 0 ? dataCollected.map(data => {
            return <div className={styles.data_collected}>
                <div className={styles.qa_ans}>
                    <p className={styles.qa}>Q: {data ? data.question : 'NA'}</p>
                    <p><img src={AnswerIcon} /> {data ? data.answer : 'NA'}</p>
                </div>
            </div>
        }) : <div> ⚠️ No Data Collected</div>
    }

    const renderCustomerInfo = () => {
        return <div className={styles.customer_info}>
            {
                teletaskLoading ? <ThreeDotLoader /> :
                customerInfo ? Object.entries(customerInfo).map(
                    ([key, value], idx) => (
                        <div className={styles.info} key={idx + 1}>
                            {`${capitalise(key)}: ${value}`}
                        </div>
                    )
                ) : <div>⚠️ No Customer Data</div>
            }
        </div>
    }

    const renderRebuttalDetails = () => {
        return rebuttalDetails && rebuttalDetails.length > 0 ?
            <div className={styles.rebuttal_wrapper}>
                {
                rebuttalDetails.map((rebuttal, idx) => {
                    return <div className={styles.rebuttal_section}>
                        <div className={styles.behaviour}>{rebuttal.behaviour}</div>
                        <div className={styles.behaviour_argument_set}>
                        <div className={styles.auditor_feedback}>
                            <div className={styles.header}>QA feedback</div>
                            <div>{rebuttal.reason || 'NA'}</div>
                        </div>
                        <div className={styles.auditor_feedback}>
                            <div className={styles.header}>Agent argument</div>
                            <div>{rebuttal.rebuttalComment || 'NA'}</div>
                        </div>
                        </div>
                      
                        <div className={styles.action_row}>
        
                            {rebuttal.rebuttalStatus == "rebutted" ?                 
                                <>
                                {
                                    rebuttal.loading ? <div className={styles.rebuttal_loader}>
                                        <FutworkButton disabled buttonStyle={rebuttal.inprogress_action == 'approved' ? 'success--outline': 'danger--outline'}>
                                            <FontAwesomeIcon icon={faSpinner} className={styles['icon_pulse']} />&nbsp;
                                            {rebuttal.inprogress_action == 'approved' ? 'Approving..' : 'Rejecting..'}</FutworkButton>
                                    </div>
                                    : 
                                    <>
                                    <FutworkButton buttonStyle={'success--outline'} onClick={() => {
                                    rebuttalHandler(rebuttal, 'approved')
                                }}>Approve</FutworkButton>
                                <FutworkButton buttonStyle={'danger--outline'} onClick={() => rebuttalHandler(rebuttal, 'rejected')}>Reject</FutworkButton>
                                    </>
                                }
                                
                                </> : rebuttal.hasOwnProperty('rebuttalStatus') ?
                                 <div className={rebuttal.rebuttalStatus == 'approved' ? styles.rebuttal_approved : styles.rebuttal_rejected}>{`Agent feedback ${rebuttal.rebuttalStatus} !`}</div>
                                : null
                                }
                        </div>
                        {idx+1 < rebuttalDetails.length ? <div className={styles['horizontal-divider-new']}></div> : null}
                    </div>
                })
            }
            </div>
        :
        <div> ⚠️ No data Present</div>
    }

    const copyToClipboard = (recordingUrl) => {
        navigator.clipboard.writeText(recordingUrl);
    }

    return(
          <aside className={styles.sidebar}>
                    <Heading text={'Call Details'} />

                    <div className={styles.call_recording}>
                        <div className={styles.call_recording_row}>
                        <span className={styles.sub_heading}>Call recording</span>
                        <span className={styles.link} 
                            onClick={() => copyToClipboard(teletaskHistoryData && teletaskHistoryData.exotel &&
                                teletaskHistoryData.exotel.RecordingUrl || "")}
                            >Copy audio link</span>
                        </div>
                        <div className={styles.audio_copy_link}>
                            {teletaskHistoryDataLoading ? <ThreeDotLoader /> : 
                            <audio controls src={teletaskHistoryData && teletaskHistoryData.exotel &&
                            teletaskHistoryData.exotel.RecordingUrl || ""} />}
                        </div>
                    </div>
                    <div className={styles.panel_collection}>
                        <Panel title={<span className={styles.sub_heading}>Rebuttal Details</span>}>
                            {renderRebuttalDetails()}
                        </Panel>
                        <Panel title={<span className={styles.sub_heading}>Data collected</span>}>
                            {renderDataCollected()}
                        </Panel>
                        <Panel title={<span className={styles.sub_heading}>Call History</span>}>
                            {renderCallHistory()}
                        </Panel>
                        <Panel title={<span className={styles.sub_heading}>Customer Info</span>}>
                            {renderCustomerInfo()}
                        </Panel>
                    </div>

                    <div className={styles.call_details}>
                        {/* <div className={styles.audio_copy_link}>
                            <audio controls src={recordingUrl} />
                        </div>
                        <span className={styles.link} onClick={() => copyToClipboard(recordingUrl)}>Copy audio link</span> */}
                        <div className={styles.sub_heading}>Disconnected by</div>
                        <input type='text' readOnly value={
                            (disconnectedBy == 'Callee' ? 'Customer' : disconnectedBy == 'Caller' ? 'Tele-caller' : disconnectedBy) || 'NA'} />
                        <div className={styles.sub_heading}>Outcome marked</div>
                        <input type='text' readOnly value={previousOutcome || outcome && outcome.title} />
                        {/* <div className={styles.sub_heading}>Correct outcome</div> */}

                        {/* <Dropdown
                            options={allOutcomes && allOutcomes.length > 0 ? allOutcomes.map(({ title }) => title) : teleproject &&
                            teleproject.outcomes.length > 0 ? teleproject.outcomes.map(({ title }) => title) : []}
                            onChange={(e) => setCorrectOutcome(allOutcomes && allOutcomes.length > 0 ? 
                            allOutcomes.find(({ title }) => title == e.value) : teleproject.outcomes.find(({ title }) => title == e.value))}
                            placeholder="Select an outcome"
                            className={styles['qmf_dropdown']}
                        /> */}

                        {/* <div className={styles.sub_heading}>Call language</div> */}
                        {/* <Dropdown
                            options={teleproject && teleproject.languages && teleproject.languages.length > 0
                                ? teleproject.languages.map(({ name }) => name) : []}
                            onChange={(e) => setCorrectLanguage(teleproject.languages.find(({ name }) => name == e.value))}
                            placeholder="Select call language"
                            className={styles['qmf_dropdown']}
                        /> */}
                    </div>

                </aside>
    )
}

export default React.memo(QmfSidebar)