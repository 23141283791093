import React, { useState, useEffect, useRef, useContext } from 'react'
//custom imports
import styles from '../../../assets/stylesheets/pages/audit/audit.module.scss'
import ProjectTitle from '../../../components/common/ProjectTitle'
import FutworkButton from '../../../uicomponents/button/Button'
import QmfHeader from '../common/qmfheader'
import QmfSidebar from '../common/qmfsidebar'
import QmfScorecard from '../common/qmfscorecard'
import useApi from '../../../hooks/useApi'
import ApiActions from '../../../actions/ApiActions'
import ThreeDotLoader from '../../../components/utilities/ThreeDotLoader'
import { RebuttalContext } from './rebuttalContext'
import { toast, ToastContainer } from 'react-toastify'
import cloneDeep from "lodash/cloneDeep";
import handleErrorMessage from '../../../helper/handleErrorMessage'


const QmfForm = (props) => {
    const {callDetails, setCallDetails, rebuttalBehviourScoreCard, setRebuttalBehviourScoreCard} = useContext(RebuttalContext)
    const {state} = props.location || {}
    const getQmfscorerulebookApi = useApi(ApiActions.getQmfscorerulebook)
    const getTeletaskCallHistoryApi = useApi(ApiActions.getTeletaskCallHistory)
    const getTeleProjectApi = useApi(ApiActions.getTeleProject)
    const getTeletaskApi = useApi(ApiActions.getTeletask)
    const getTeletaskHistoryApi = useApi(ApiActions.getTeletaskHistory)
    const submitQmfScoreApi = useApi(ApiActions.submitQmfScore)

    const [qmfScoreRules, setQmfScoreRules] = useState('')
    const [qmfCategories, setQmfCategories] = useState('')

    console.log(callDetails)
    const mutatRuleBook = (rules) => {
        const { id } = rules
        let setOfRules = rules[id]
        if(callDetails && callDetails.feedbacks && callDetails.feedbacks.length > 0){
            let extractCategoryBasedOnFdbk = [...new Set(callDetails.feedbacks.map(each => each.category))]
            let extractBehaviourBasedOnFdbk = [...new Set(callDetails.feedbacks.map(each => each.behaviour))]
            // const extractExistingScores = callDetails && callDetails.qmfGrades.map()
            setQmfCategories([...extractCategoryBasedOnFdbk])
            let tempRules = setOfRules.map(rule => {
                if(extractCategoryBasedOnFdbk.includes(rule.category) && 
                    extractBehaviourBasedOnFdbk.includes(rule.behaviour)){
                        let previousGrade = callDetails && callDetails.qmfGrades.find(grade => grade.behaviour == rule.behaviour)
                        let feedbackForParamter = callDetails && callDetails.feedbacks.find(fdbk => fdbk.behaviour == rule.behaviour)
                        return {
                            ...rule,
                            "result": previousGrade.result,
                            "score": previousGrade.score,
                            "feedback": "",
                            "description": rule.description,
                            "disabled": true,
                            "pastFeedback": feedbackForParamter
                        }
                }
            }).filter(Boolean)
            setQmfScoreRules(tempRules)
        }else{
            setQmfCategories([...new Set(rules[id].map(rule => rule.category))])
            let tempRules = setOfRules.map(rule => {
                return {
                    ...rule,
                    "result": "Yes",
                    "score": rule.weightage,
                    "feedback": "",
                    "description": rule.description
                }
            })
            setQmfScoreRules(tempRules)
        }
    }

    useEffect(() => {
        if(callDetails == ''){
            if(!state){
                window.history.back()
                return
            }
            setCallDetails(state.callDetails)
        }
        const {teletaskhistory, projectId} = state.callDetails
        getQmfscorerulebookApi.request()
        getTeletaskCallHistoryApi.request({ teletaskHistory: callDetails ? callDetails.teletaskhistory : teletaskhistory })
        getTeletaskHistoryApi.request(callDetails ? callDetails.teletaskhistory : teletaskhistory)
        // getTeleProjectApi.request(callDetails ? callDetails.projectId : projectId)
        // getTeletaskApi.request("6616318bafe31a00125ba283")
    }, [])



    let { data } = getQmfscorerulebookApi || {}
    const { loading, error: ruleBookError } = getQmfscorerulebookApi
    const { data: teletaskHistoryData, loading: teletaskHistoryDataLoading, error: teletaskHistoryDataError} = getTeletaskHistoryApi || {}
    const { data: callHistory, loading: callHistoryLoading, error: teletaskHistoryError } = getTeletaskCallHistoryApi || {}
    const { data: teletask, loading: teletaskLoading, error: teletaskError} = getTeletaskApi || {}
    let { data: auditSubmitResult } = submitQmfScoreApi.data || {}
    const { error: auditSubmitError, loading: auditSubmitLoading } = submitQmfScoreApi || {}


    useEffect(()=>{
        if(!teletaskHistoryDataLoading && teletaskHistoryData){
            getTeletaskApi.request(teletaskHistoryData.teletask)
            // getTeletaskHistoryApi.request(callDetails ? callDetails.teletaskhistory : teletaskhistory)
        }
    },[teletaskHistoryDataLoading])

    useEffect(()=>{
        if(!auditSubmitLoading && auditSubmitResult){
            const { verdict } = auditSubmitResult
            if(verdict){
                toast('Failed to updated the score')
            }else{
                toast('The score is updated ! You will be redirected to the previous page in 4 seconds')
                setTimeout(() => {
                    props.history.push({
                        pathname: `/audit/rebuttal`,
                        state: {}
                    })
                }, 4000);
            }
            // getTeletaskHistoryApi.request(callDetails ? callDetails.teletaskhistory : teletaskhistory)
        }
        if(auditSubmitError){
            toast(handleErrorMessage(auditSubmitError))
        }
    }, [auditSubmitLoading])

    useEffect(() => {
        if (data && Object.keys(data).length > 0) {
            mutatRuleBook(data)
        }
    }, [data])


    const rebuttalHandler = async (detail, action) => {
        const {feedbackId = '', } = detail || {}
        const {auditId = ''} = callDetails || {}

        let tempCallDetails = {...callDetails}
        tempCallDetails.feedbacks.map(fdbk => {
            if(fdbk.feedbackId == detail.feedbackId){
                fdbk.loading = true
                fdbk.inprogress_action = action
            }
        })
        setCallDetails(tempCallDetails)

        try {
            const payload = {
                "auditId": auditId,
                "feedbackId": feedbackId,
                "rebuttalStatus": action
            }
    
            
            let result = await ApiActions.auditRebuttalFeedback(payload)
            if(result.status == 200){
                let tempCallDetails = {...callDetails}
                tempCallDetails.feedbacks.map(fdbk => {
                    if(fdbk.feedbackId == detail.feedbackId){
                        fdbk.rebuttalStatus = action
                        if(action == 'approved'){
                            let tempScoreCard = [...qmfScoreRules]
                            let updatedData = tempScoreCard.map(paramter => {
                                if(paramter.pastFeedback.feedbackId == detail.feedbackId){
                                    paramter.disabled = false
                                    paramter.isScoreUpdated = false
                                }
                                return paramter
                            })
                            setQmfScoreRules(updatedData)
                        }
                    }
                    delete fdbk.loading
                    delete fdbk.inprogress_action
                })
                props.history.push({
                    pathname: `${props.location.pathname}`,
                    state: {
                        callDetails: tempCallDetails
                    }
                })
                setCallDetails(tempCallDetails)
            }
        } catch (error) {
            let tempCallDetails = {...callDetails}
            tempCallDetails.feedbacks.map(fdbk => {
            if(fdbk.feedbackId == detail.feedbackId){
                delete fdbk.loading
                delete fdbk.inprogress_action
            }
            })
            setCallDetails(tempCallDetails)
            const {data} = error.response || {}
            toast(data && data.msg)
        }
        // setTimeout(() => {
        //     let tempCallDetails = {...callDetails}
        //     tempCallDetails.feedbacks.map(fdbk => {
        //         if(fdbk.feedbackId == detail.feedbackId){
        //             fdbk.rebuttalStatus = action
        //             if(action == 'approved'){
        //                 let tempScoreCard = [...qmfScoreRules]
        //                 let updatedData = tempScoreCard.map(paramter => {
        //                     if(paramter.pastFeedback.feedbackId == detail.feedbackId){
        //                         paramter.disabled = false
        //                         paramter.isScoreUpdated = false
        //                     }
        //                     return paramter
        //                 })
        //                 setQmfScoreRules(updatedData)
        //             }
        //         }
        //         delete fdbk.loading
        //         delete fdbk.inprogress_action
        //     })
        //     setCallDetails(tempCallDetails)
        // }, 2500);
        // return true
    }

    const updateResultHandler  = (behavior, value) => {
        let tempScoreCard = [...qmfScoreRules]
        let updatedData = tempScoreCard.map(paramter => {
            if(behavior.sequence == paramter.sequence){
                paramter.result = value
                paramter.score = value == 'NA' ? 0 : paramter.weightage
                paramter.feedback = ''
                paramter.isScoreUpdated = true
            }
            return paramter
        })
        setQmfScoreRules(updatedData)
    }

    const submitAudit = async () => {
        console.log('submit audit called', callDetails)
        const {userId = '', projectId='', teletaskhistory='', qmfScoreCard, qmfGrades } = callDetails || {}
        const tempGrades = cloneDeep(qmfGrades)
        const {previousOutcome = '', callLanguage = '', auditType = ''} = qmfScoreCard || {}
        const updatedGrades = tempGrades.map(param1 => {
            qmfScoreRules && qmfScoreRules.find(param2 => {
                if(param2.behaviour == param1.behaviour){
                    param1.result = param2.result
                    param1.score = param2.score
                }
            })
            return param1
        })
        console.log('updatedGrades', updatedGrades)
        console.log('qmfScoreRules', callDetails.qmfGrades)

        const payload = {
            userId,
            "teleprojectId": projectId,
            "teletaskhistoryId": teletaskhistory,
            "auditType": auditType ? auditType : "performanceAudit",
            "callLanguage": callLanguage,
            "qmfGrades": updatedGrades && updatedGrades.sort((a, b) => a.sequence - b.sequence).map(rule => {
                // delete rule.description
                return rule
            }),
            "outcome": teletaskHistoryData ? teletaskHistoryData.teleproject.outcomes.find(({ title }) => title == previousOutcome) : {}
            
        }
        console.log('payload', payload)
        submitQmfScoreApi.request(payload)
        // setShowAudit(true)

        //delete
        // "description": rule.description,
        // "disabled": true,
        // "pastFeedback": feedbackForParamter
    }

    const checkScoreSubmitCondition = () => {
        const checkApprovedRebuttal = callDetails && callDetails.feedbacks && callDetails.feedbacks.filter(fdbk => fdbk.rebuttalStatus == 'approved') || []
        const checkRebuted = callDetails && callDetails.feedbacks && callDetails.feedbacks.filter(fdbk => fdbk.rebuttalStatus == 'rebutted') || []
        const scoreUpdatePending = qmfScoreRules && qmfScoreRules.filter(paramter => paramter.isScoreUpdated == true) 
        
        if(scoreUpdatePending && scoreUpdatePending.length > 0){
            return false
        }else if(checkRebuted && checkRebuted.length > 0){
            return true
        }else{
            return true
        }
        
        // return callDetails.feedbacks.filter(fdbk => fdbk.rebuttalStatus == 'approved') ? false : true
        // return callDetails && callDetails.feedbacks && callDetails.feedbacks.filter(fdbk => fdbk.rebuttalStatus == 'approved').length > 0 && 
        // callDetails.feedbacks.filter(fdbk => fdbk.rebuttalStatus !== 'rebutted').length == 0 ? false : true

        // return false
    }

    const formatTeletask = (teletask= null) => {
        return teletask 
        // ? 
        // {
        //     // recordingUrl: teletask.exotel,
        //     ...teletask
        // }
        // : teletask
    }


    const headerProps = {
        teleproject: callDetails ? callDetails.title : '',
        caller: callDetails ? callDetails.callerName : '',
        teleprojectDetails: teletaskHistoryData ? teletaskHistoryData.teleproject : {},
        teleprojectDetailsLoading : teletaskHistoryDataLoading,
        goBack: () => {
            props.history.push({
                pathname: `/audit/rebuttal`,
                state: {}
            })
        }
    }
    return(
        <div className={styles.qmf_form_page_wrapper}>
            <QmfHeader {...headerProps}/>
             {/* <header className={styles.qmf_form_header}>
             <ProjectTitle title={
                    <ul className={styles.breadcrumbs}>
                        <li>QMF</li>
                        <li>{'NA'}</li>
                        <li>{'NA'}</li>
                    </ul>
                }
                    goBack={() => props.history.push({
                        pathname: `/audit/rebuttal`,
                        // state: {
                        //     callerDetails: props.location.state.callerDetails,
                        //     activeTab: props.location.state.activeTab,
                        //     page: props && props.location && props.location.state && props.location.state.page ? 
                        //     props.location.state.page : undefined,
                        //     callPage: props && props.location && props.location.state && props.location.state.callPage ? 
                        //     props.location.state.callPage : undefined,
                        //     query: searchBy,
                        //     outcomeQuery: outcomeQuery
                        // }
                    })} />
                <div className={styles.button_links}>
                    <FutworkButton
                        buttonSize='x-large'
                        // onClick={() => setShowOutcome(true)}
                    >View Outcomes</FutworkButton>
                    <FutworkButton
                        buttonSize='x-large'
                        // onClick={() => setShowScript(true)}
                    >View Script</FutworkButton>
                </div>
             </header> */}

             {/* sidebar  */}
             <div className={styles.flex_container}>
              <QmfSidebar callHistory={callHistory} callHistoryLoading={callHistoryLoading} rebuttalHandler={rebuttalHandler}
              teletaskLoading={teletaskLoading} teletask={teletask} teletaskHistoryDataLoading={teletaskHistoryDataLoading} 
              teletaskHistoryData={teletaskHistoryData} />
              {
                    loading ?
                        <div style={{ display: 'flex', width: '70%', justifyContent: 'center', alignItems: 'center' }}>
                            <ThreeDotLoader />
                        </div>
                        :
                    <QmfScorecard qmfScoreRules={qmfScoreRules} qmfCategories={qmfCategories} 
                    updateResultHandler={updateResultHandler} checkScoreSubmitCondition={checkScoreSubmitCondition} 
                    submitAudit={submitAudit} auditSubmitLoading={auditSubmitLoading}/>
              }
            </div>
            <ToastContainer/>
        </div>
    )
}

export default QmfForm