import React from 'react'
//custom imports
import styles from '../../../assets/stylesheets/pages/audit/audit.module.scss'
import Heading from '../../../uicomponents/heading/heading'
import CustomTooltip from '../../../components/utilities/ToolTip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPenToSquare, faSpinner} from '@fortawesome/free-solid-svg-icons'
import { RefreshIcon, AnswerIcon, AttachmentIcon, InfoIcon, YellowStarIcon } from '../../../assets/images'
import FutworkButton from '../../../uicomponents/button/Button'

const QmfScorecard = (props) => {
    const {qmfScoreRules, qmfCategories, updateResultHandler, checkScoreSubmitCondition, submitAudit, auditSubmitLoading} = props

    const totalWeigtage = (item) => {
        let totalWeight = 0
        totalWeight = qmfScoreRules && qmfScoreRules.filter((it) => it.category == item).reduce((acc, curr) => acc + curr.weightage, 0)
        return <span>{totalWeight}</span>
    }
    const resultOptions = [
        { label: 'YES', value: 'Yes' },
        { label: 'NO', value: 'No' },
        { label: 'NA', value: 'NA' }
    ]

    // const checkScoreSubmitCondition = () => {
    //     console.log('checkScoreSubmitCondition')
    //     return false
    // }

    return(
             <main className={styles.main_content}>
                            <div className={styles.main_content_header}>
                                <Heading text={'Score card'} />
                            </div>
                            {
                                qmfCategories && qmfCategories.length > 0 && qmfCategories.map(item => {
                                    return (
                                        <>
                                            <div className={styles['horizontal-divider-new']}></div>
                                            <div className={styles['çategory-section']}>
                                                <p className={styles['category-name']}>{item} [ {totalWeigtage(item)} ]</p>
                                                <div className={styles['grid-layout']}>
                                                    <div className={styles['parameter']}>Parameters</div>
                                                    <div className={styles['parameter']}>Result</div>
                                                    <div className={styles['parameter']}>Weightage</div>
                                                    <div className={styles['parameter']}>Score</div>
                                                    <div className={styles['parameter']}>Feedback</div>
                                                </div>
                                                {
                                                    qmfScoreRules && qmfScoreRules.length > 0 && qmfScoreRules.map((i, idx) => {
                                                        if (i.category == item) {
                                                            return <>
                                                            <div className={styles['grid-layout']} key={idx + 1}>
                                                                <div className={i.isFatal ? [styles['param-name'], styles['fatal']].join(' ') : styles['param-name']}>{i && i.behaviour}
                                                                <CustomTooltip placement={'bottom'} description={i.description || 'NA'}
                                                                component={<img src={InfoIcon} alt={i.description || 'NA'} />} />
                                                                </div>
                                                                <div>
                                                                    <select name="result" id={`param-result-${idx + 1}`} 
                                                                    disabled={i.disabled}
                                                                    className={i.disabled ? styles.disable_result : !i.isScoreUpdated && !i.disabled ? styles.highlight_result_dropdown : ''}
                                                                    onChange={(e) => updateResultHandler(i, e.target.value)}
                                                                        value={i && i.result}>
                                                                        {
                                                                            resultOptions.map((option => {
                                                                                return <option value={option.value}
                                                                                    // disabled={i.pastFeedback && option.label == 'NO' ? true : false}
                                                                                    hidden={(i.isFatal && option.label == 'NA') || i.pastFeedback && option.label == 'NO' ? true : false} key={option.value}>{option.label}</option>
                                                                            }))
                                                                        }
                                                                    </select>

                                                                </div>
                                                                <div className={i && i.isFatal ? [styles['score'], styles['fatal'], i.disabled ? styles['disable_feedback'] : ''].join(' ') : [styles['score'], i.disabled ? styles['disable_feedback'] : ''].join(' ')}>{i && i.isFatal ? 'FATAL' : i && i.weightage}</div>
                                                                <div className={[styles['score'], i.disabled ? styles['disable_feedback'] : ''].join(' ')}>{i && i.isFatal ? '--' : i && i.score}</div>
                                                                <div className={i && (i.result == 'Yes' || i.result == 'NA') ? [styles['score'], styles['disable_feedback'], styles['btn']].join(' ') : [styles['score'], styles['btn'], i.pastFeedback ? styles['disable_feedback'] : ''].join(' ')} onClick={() => {
                                                                    if (i && i.result == 'No') {
                                                                        // setSelectedRowData(i);
                                                                        // setShowFeedback(true)           
                                                                    }
                                                                }}>
                                                                    <FontAwesomeIcon icon={i.feedback ? faPenToSquare : faPlus} className={styles['plus']} />
                                                                </div>
                                                            </div>
                                                            {/* {!i.isScoreUpdated && !i.disabled ? <b>Update the result for the above parameter as it's rebuttal is Approved</b> : null} */}
                                                            </>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </>
                                    )
                                })
                            }
                            <div className={styles['horizontal-divider-new']}></div>
                            <div className={styles['audit_footer']}>
                                <div className={styles['audit_score']}>
                                    {/* <span className={styles['label']}>Audit score</span>
                                    <div className={[styles['score'], styles['btn']].join(' ')} onClick={auditScore}>
                                        {auditResult && auditResult.score == null ?
                                            <img src={RefreshIcon} width='16px' alt='refresh-score' /> : auditResult && auditResult.score}
                                    </div> */}
                                </div>
                                <div className={styles['button-section']}>
                                    <FutworkButton buttonSize='medium w-100' buttonStyle='success--solid'
                                    disabled={auditSubmitLoading || checkScoreSubmitCondition()}
                                        // disabled={auditResult && auditResult.score == null ? true : false}
                                    onClick={submitAudit}
                                    >{auditSubmitLoading ? 
                                        <>
                                        <FontAwesomeIcon icon={faSpinner} className={styles['icon_pulse']} />&nbsp;
                                        <span>SUBMITTING...</span>
                                        </>
                                    : 'SUBMIT'}</FutworkButton>
                                </div>
                            </div>
                            <div className={styles['horizontal-divider-new']}></div>
            </main>
    )
}

export default React.memo(QmfScorecard)