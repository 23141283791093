import ApiActions from "../../../actions/ApiActions";
import generatePayload from "../../../helper/generatePayloadForTable";
import axios from "axios";

const fetchRebuttalList = async(skip, limit, sort, search, dynamicSearch = true) => {
    let payload = generatePayload(skip, limit, sort, search, dynamicSearch)
    return await ApiActions.getRebuttalCallsList(payload)
    try {
        return await axios.get('http://localhost:3001/rebuttals');
    } catch (error) {
        throw error
    }
}

export {
    fetchRebuttalList
}